import React, { useEffect } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import App from './App';

export default withRouter((props) => {
    if (props.location.pathname === '/admin/login')
        return null

    

    return (<App >


    </App>)

})