import { observable, action, computed } from 'mobx'
import {GET,DETAIL_ACCOUNT} from '../service'
export const KEY = 'DkdL8OVPdp';
class User {
    @observable role = '';
    @observable auth = false;
    @observable name = '';
    @observable tel = '';
    @observable username = '';
    @observable over_all_active = 0
    @observable image_pic =''
    @observable phonenumber = ''
    constructor() {
        this.initial();
    }
    @action
    async initial() {
        try {
            const res = await localStorage.getItem(KEY);
            if (res) {
                const obj = JSON.parse(res);
                for (const key in obj) {
                    if (obj.hasOwnProperty(key)) {
                        const element = obj[key];
                        this[key] = element;
                    }
                }
                this.auth = true;
            }
        } catch (error) {
            console.log(error)
        }
    }
    @computed
    get returnToken() {
        return this.access_token
    }

    @action
    async login(result) {
        console.log('result_login',result);
        let role = result.role
        let access_token = result.access_token
        let account_id = result.account_id
        let image_pic = result.image_pic
        let name = result.name
        let phonenumber = result.phonenumber
        try {
            await localStorage.setItem(KEY, JSON.stringify({ role, access_token, account_id,image_pic ,name,phonenumber}));
            this.account_id = account_id
            this.role = role;
            this.access_token = access_token
            this.image_pic=image_pic
            this.name = name
            this.phonenumber = phonenumber
            this.auth = true;
        } catch (error) {
            console.log(error)
        }
    }
    @action
    async setImage_pic(account_id){
        try {
            let r =await GET(DETAIL_ACCOUNT(account_id));
            if(r.success){
                let image_pic = r.result[0].url
                await localStorage.setItem(KEY, JSON.stringify({role:this.role,access_token:this.access_token,account_id:this.account_id,image_pic}));
                this.image_pic=image_pic
            }
        } catch (error) {
            console.log(error)
        }
      
    }
    async signOut() {
        try {
            await localStorage.removeItem(KEY);
            this.auth = false;
            this.role = '';
            this.access_token = ''
            this.account_id = '';
            this.image_pic=''
            this.name = ''
            this.over_all_active = 0;
            this.phonenumber =''
            console.log('sign out');

        } catch (error) {
            console.log(error)
        }
    }



}

export default new User();