import { create } from "apisauce";
import UserMobx from "./mobx/user";
export const ip = "https://parichgems.com:2096/api/v1/parichgems";
//export const ip = "http://192.168.1.105:5100/api/v1/parichgems";
const TOKEN_PASS = "WEW9ZUB64GDWD7Y4HN7CEP9YJ49L8LKZ7ZJT6Q2AELUTUSQ";

const api = create({
  baseURL: ip,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 30000, // 10 seconds
});
const apiupload = create({
  baseURL: ip,
  withCredentials: true,
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "multipart/form-data",
  },
  timeout: 30000, // 10 seconds
});

const apiapp = create({
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "multipart/form-data",
  },
  timeout: 30000, // 10 seconds
});
export const POST = (path, obj, token = UserMobx.returnToken) =>
  new Promise((resolve, reject) => {
    api.post(path, obj, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });

export const GET = (path, token = UserMobx.returnToken) =>
  new Promise((resolve, reject) => {
    api.get(
        path,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });

export const UPLOAD = (path, formdata, token = UserMobx.returnToken) =>
  new Promise((resolve, reject) => {
    apiupload.post(path, formdata, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });
export const LOGIN = "/authen/login";
export const CHANGPASSWORD = "/account/changpassword";
export const GETACCOUNTID = "/account/getAccountId";
export const CREATE_ACCOUNT = "/account/createAccount";
export const DETAIL_ACCOUNT = (account_id) =>`/account/getAccountById?account_id=${account_id}`;
export const EDIT_ACCOUNT = "/account/editAccountById";
export const GETALLACCOUNT = "/account/getAllAccount";
export const UPDATEACCOUNT = "/account/updateStatus";
export const DELACCOUNT = "/account/delAccountById";
export const VALIDATE_ACCOUNT = "/account/validateUserName"
////////////////CUSTOMER////////////////////
export const REGISTER = "/authen/customerRegister";
export const GET_PROVINCE = "/authen/getProvince";
export const VALIDATE_PHONE = "/authen/validatePhonenumber";
export const GETALLCUSTOMER = "/authen/getAllCustomer";
export const DETAIL_CUSTOMER = (customer_id) =>`/authen/getDetailCustomer?customer_id=${customer_id}`;
export const VALIDATE_PHONE_CUSTOMER = "/authen/validatePhonenumberByUser";
export const UPDATECUSTOMER = "/authen/updateCustomerById";

/////////////PRODUCT/////////////
export const UPSERT_PRODUCT = '/product/upsertProduct'
export const GENPRODUCTID = (type)=>`/product/genProductId?type=${type}`
export const GETALLPRODUCT = '/product/getAllProduct'
export const DETAIL_PRODUCT = (product_id) => `/product/gteDetailProduct?product_id=${product_id}`
export const DEL_PRODUCT = '/product/delProductById'
export const GETPRODUCTLIST = '/product/getProductList'
export const DUPLICATE_PRODUCT = '/product/duplicateProductById'
/////////////INVOICE/////////////

export const GENINVOICEID = `/invoice/genInvoice`
export const UPSERT_INVOICE = '/invoice/upsertInvoice'
export const GETALLINVOICE = '/invoice/getAllInvoiceByUser'
export const UPDATERECEIVEPRODUCT = '/invoice/updateStatusReceiveProduct'
export const CANCEL_INVOICE = '/invoice/cancelInvoice'
export const DETAIL_INVOICE = (invoice_id) =>`/invoice/getDetailInvoice?invoice_id=${invoice_id}`
export const UPDATE_INVOICE = '/invoice/updateInvoice'
export const GETINVOICEBYACCOUNT = (customer_id) => `/invoice/getInvoiceByCustomer?customer_id=${customer_id}`
export const GETINVOICEBYDATE = '/invoice/getInvoiceByDate'
export const UPDATE_INVOICEDETAIL = '/invoice/upsertImageInvoice'