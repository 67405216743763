import React, { Component } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
// import { renderRoutes } from 'react-router-config';
import "./App.scss";
import Routes from "./routes/Root";
// import user from './mobx/user'

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
// const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import("./views/Pages/Login/Login"));
const Register = React.lazy(() => import("./views/Pages/Register"));
const Splashsceen = React.lazy(() => import("./views/Pages/Splashsceen"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
// const Page500 = React.lazy(() => import('./views/Pages/Page500'));
const Makepdf = React.lazy(() => import("./views/Pages/Testpdf"));

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route path={"/loading"} name="Register Page" render={(props) => <Splashsceen {...props} />} />
            <Route path={"/pdf"} name="pdf" render={(props) => <Makepdf {...props} />} />
            <Route path={'/Register'} name="Register Page" render={props => <Register {...props} />} />
            <Route path={'/admin/login'} name="Login Page" render={props => <Login {...props} />} />
            <Routes />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
