import React from 'react';
import { reaction } from 'mobx';


const Dashboard = React.lazy(() => import('./views/Pages/Dashboard/DashBoardScene'));
const OverallScene = React.lazy(() => import('./views/Dashboard/DashboardScene'));
const CrateAccountScene = React.lazy(() => import('./views/Pages/Account/CreateAccountScene'));
const DashBoardAccountScene = React.lazy(() => import('./views/Pages/Account/DashBoardAccountScene'));
const DetailAccountScene = React.lazy(() => import('./views/Pages/Account/DetailAccountScene'));
const DashBoardCustomerScene = React.lazy(() => import('./views/Pages/Customer/DashBoardCustomerScene'));
const DetailCustomerScene = React.lazy(() => import('./views/Pages/Customer/DetailCustomerScene'));
const DashBoardProductScene =  React.lazy(() => import('./views/Pages/Product/DashBoardProductScene'));
const ChangPasswordScene = React.lazy(() => import('./views/Users/ChangpasswordScene'));
const ProfileUserScene = React.lazy(() => import('./views/Pages/User/ProfileUserScene'));
const CreateProductScene = React.lazy(() => import('./views/Pages/Product/CreateProductScene'));
const DetailProductScene = React.lazy(() => import('./views/Pages/Product/DetailProductScene'));
const DashBoardInvoiceScene = React.lazy(() => import('./views/Pages/Invoice/DashBoardInvoiceScene'));
const CreateInvoiceScene = React.lazy(() => import('./views/Pages/Invoice/CreateInvoiceScene'));
const DetailInvoiceScene = React.lazy(() => import('./views/Pages/Invoice/DetailInvoiceScene'));
const widget =  React.lazy(() => import('./views/Widgets/Widgets'));
const routes = [

  { path: '/home', exact: true, name: 'Home' },
  { path: '/admin/dashboard', exact: true, name: 'Dashboard', component: Dashboard },
  { path: '/overall', exact: true, name: 'Overall', component: OverallScene },
  { path: '/admin/account', exact: true, name: 'Account', component: DashBoardAccountScene },
  { path: '/admin/account/create_account', name: 'CreateAccount', component: CrateAccountScene },
  { path: '/admin/account/detail_account/:account_id', name: 'DetailAccount', component: DetailAccountScene },
  { path: '/admin/customer', exact: true, name: 'Customer', component: DashBoardCustomerScene },
  { path: '/admin/customer/detail_customer/:customer_id', name: 'DetailCustomer', component: DetailCustomerScene },

  { path: '/changpassword', exact: true, name: 'Changpassword', component: ChangPasswordScene },
  { path: '/admin/Profile', exact: true, name: 'Profile', component: ProfileUserScene },
  { path: '/admin/product', exact: true, name: 'Product', component: DashBoardProductScene },
  { path: '/admin/product/create_product', name: 'CreateProduct', component: CreateProductScene },
  { path: '/admin/product/detail_product/:product_id', name: 'DetailProduct', component: DetailProductScene },
 
  { path: '/admin/invoice', exact: true, name: 'Invoice', component: DashBoardInvoiceScene },
  { path: '/admin/invoice/create_invoice', name: 'CreateInvoice', component: CreateInvoiceScene },
  { path: '/admin/invoice/detail_invoice/:invoice_id', name: 'DetailInvoice', component: DetailInvoiceScene },

  { path: '/admin/widget', exact: true, name: 'widget', component: widget },
];

export default routes;
