import React, { Component, Suspense, useEffect, useState } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import * as router from "react-router-dom";
import { Container } from "reactstrap";
import user from "../mobx/user";
import {
    AppAside,
    AppFooter,
    AppHeader,
    AppSidebar,
    AppSidebarFooter,
    AppSidebarForm,
    AppSidebarHeader,
    AppSidebarMinimizer,
    AppBreadcrumb,
    AppSidebarNav,
} from "@coreui/react";
// sidebar nav config
import { navigation_all, navigation_sale ,navigation_stock} from "../_nav";
// routes config
import routes from "../routes";

const DefaultAside = React.lazy(() =>
    import("../containers/DefaultLayout/DefaultAside")
);
// const DefaultFooter = React.lazy(() => import('../containers/DefaultLayout/DefaultFooter'));
const DefaultHeader = React.lazy(() =>
    import("../containers/DefaultLayout/DefaultHeader")
);

export default withRouter((props) => {
    const [role, setRole] = useState('')
    const [navigation, setNavigation] = useState('')
    useEffect(() => {

        setRole(user.role)
        // setNavigation(navigation_all)
        if (props.location.pathname === '/' || props.location.pathname === '/Register') {
            return props.history.push('/loading')
        }
        if (!user.auth) {
            return props.history.push('/admin/login')
        }

        // switch(role){
        //     case 'ผู้จัดการ':
        //         setNavigation(navigation_all)
        //         break
        //     case 'ฝ่ายขาย':
        //         setNavigation(navigation_sale)
        //         break
        // }




    }, [props.location.pathname])//[props.location.pathname]

    const loading = () => (
        <div className="animated fadeIn pt-1 text-center">Loading...</div>
    );
    const signOut = () => {
        user.signOut();
        props.history.push("/admin/login");
    };
    const onUser = () => {
        props.history.push("/users");
    };
    const onChangpassword = () => {
        props.history.push("/changpassword");
    };
    const onProfile = () => {
        props.history.push("/admin/Profile");
    }
    const onManagement = () => {
        props.history.push("/manage");
    };



        return (
            <div className="app">
                <AppHeader fixed>
                    <Suspense fallback={loading()}>
                        <DefaultHeader onLogout={e => signOut()} onUser={e => onUser()} onChangpassword={e => onChangpassword()} onProfile={e=>onProfile()} onManagement={e => onManagement()} />
                    </Suspense>
                </AppHeader>
                <div className="app-body" >
                    <AppSidebar fixed display="lg">
                        <AppSidebarHeader />
                        <AppSidebarForm />
                        <Suspense>

                            <AppSidebarNav navConfig={role==='ผู้จัดการ'?navigation_all:role==='ฝ่ายขาย'?navigation_sale:navigation_stock} {...props} router={router} />
                        </Suspense>
                        <AppSidebarFooter />
                        <AppSidebarMinimizer />
                    </AppSidebar>
                    <main className="main">
                        <AppBreadcrumb appRoutes={routes} router={router} />
                        <Container fluid>
                            <Suspense fallback={loading()}>
                                <Switch>
                                    {routes.map((route, idx) => {
                                        return route.component ? (
                                            <Route
                                                key={idx}
                                                path={route.path}
                                                exact={route.exact}
                                                name={route.name}
                                                render={props => (
                                                    <route.component {...props} />
                                                )} />
                                        ) : (null);
                                    })}
                                    <Redirect from="/" to="/loading" />
                                </Switch>
                            </Suspense>
                        </Container>
                    </main>
                    <AppAside fixed>
                        <Suspense fallback={loading()}>
                            <DefaultAside />
                        </Suspense>
                    </AppAside>
                </div>
                <AppFooter>
                    <Suspense fallback={loading()}>
                        {/* <DefaultFooter /> */}
                    </Suspense>
                </AppFooter>
            </div>
        );
    
});
